import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import './app.styles.scss'
import DashboardPage from './pages/dashboard/dashboard.page'
import RequireLogin from './components/require-login/require-login.component'
import DefaultTemplate from './templates/default/default.template'
import AuthTemplate from './templates/auth/auth.template'
import AuthController from './pages/auth/auth.controller'
import ApplicationController from './pages/application/application.controller'
import SettingController from './pages/settings/setting.controller'

const AppController = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/auth" component={() => <AuthTemplate><AuthController /></AuthTemplate>} />
          <Route path="/applications" component={() => <RequireLogin><DefaultTemplate><ApplicationController /></DefaultTemplate></RequireLogin>} />
          <Route path="/settings" component={() => <RequireLogin><DefaultTemplate><SettingController /></DefaultTemplate></RequireLogin>} />
          <Route path="/" component={() => <RequireLogin><DefaultTemplate><DashboardPage /></DefaultTemplate></RequireLogin>} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default AppController
