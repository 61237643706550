import axios from 'axios'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        processing: false,
        code: "",
        errors: {},
        flag: {
            type: "success",
            text: "Verification Code sent to your email"
        }
    }
}

const isValid = instance => {
    let validate = instance.state.code.length === 6
    let stateUpdate = {
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Invalid Code"
        }
    }
    ScrollIntoViewById('verify')
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const verify = (instance) => {
    instance.setState({
        ...instance.state,
        processing: true
    })
    axios({
            method: 'post',
            url: API_ENDPOINT + instance.props.url,
            headers: {
                "Authorization": instance.props.authorization
            },
            data: {
                code: instance.state.code
            }
        }).then(response => handleSetupResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                processing: false
            })
            alert(error)
        })
}

const handleSetupResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.onCodeRecieved(instance.state.code)
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        verify: () => verify(instance)
    }
}

export default Service