import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Form from './form.page'
import FormHelper from '../../../utils/FormHelper'
import Worker from './worker'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import Setup from '../components/setup/setup.component'
import Verify from '../components/verify/verify.component'
import Complete from '../components/complete/complete.component'

class Reset extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.worker.isValid() && this.worker.reset()
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return <Verify authorization={this.state.authorization} url="/users/verify" onCodeRecieved={(code) => this.setState({ ...this.state, code: code, tab: 2 })} />
            case 2:
                return <Setup authorization={this.state.authorization} url="/users/reset" code={this.state.code} onSetupCompleted={() => this.setState({ ...this.state, tab: 3 })} />
            case 3:
                return <Complete title="Password Updated" />
            default:
                return <div className="card login">
                    {this.state.processing && <Dialog><Loading /></Dialog>}
                    <h3>Reset Password</h3>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form onSubmit={this.onSubmit} onChange={FormHelper(this).onChange} {...this.state} />
                    <div className="signup">
                        <p>or</p>
                        <button onClick={() => this.props.history.push('/auth/login')}>Login</button>
                    </div>
                </div>
        }
    }

    render() {
        return this.getCurrentTab()
    }
}

export default connect(null)(withRouter(Reset))