import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../assets/icons/new-light.svg'
import Pagination from '../../components/pagination/pagination.component'
import './applications.styles.scss'
import Service from './applications.service'
import NumberFormat from 'react-number-format'
import FlutterwavePopup from '../../components/flutterwave/popup.component'

class Orders extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrders()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadOrders())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadOrders()
    }

    getPaymentInfo = () => {
        return {
            name: this.state.flutterwave.invoice.user.firstname + " " +this.state.flutterwave.invoice.user.lastname,
            email: this.state.flutterwave.invoice.user.username,
            amount: this.state.flutterwave.invoice.balance,
            currency: "NGN",
            txref: "hub_web_api_" + this.state.flutterwave.id
        }
    }

    callback = (response, handle) => {
        //handle.close()
        window.location = "/applications/all"
    }

    onPaymentDialogClosed = (handle) => {
        window.location = "/applications/all"
    }

    getStatusLabel = status => {
        switch(status){
            case 1:
                return "Pending"
            case 2:
                return "Processing"
            case 3:
                return "Processed"
            case 4:
                return "Cancelled"
            default:
                return "Unknown"
        }
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showPaymentDialog && <FlutterwavePopup callback={this.callback} onClose={this.onPaymentDialogClosed} {...this.getPaymentInfo()} />}
                <div className="applications">
                    <div className="header">
                        <h4>Applications</h4>
                        <div className="buttons">
                            <Link className="btn" to="/applications/create/techcamp"><img alt="" src={iconNew} />New Application</Link>
                            
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Amount(N)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.orders.map((order, key) => <tr key={key}>
                                    <td>{order.number}</td>
                                    <td><NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{order.invoice.status === 1? <button onClick={() => this.service.requestPayment(order.invoice.number)}>Pay Now</button>: this.getStatusLabel(order.status)}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Orders))