import React from 'react'
import { Switch, Route } from "react-router-dom"
import Techcamp from './create/techcamp/techcamp.page'
import './common.styles.scss'
import Applications from './applications.page'

const ApplicationController = () => {
    return (
        <div className="application">
            <Switch>
                <Route path="/applications/create/techcamp" component={Techcamp} />
                <Route path="/applications/all" component={Applications} />
            </Switch>
        </div>
    )
}

export default ApplicationController