import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../../../config'
import { StringValidationRule, DateValidationRule } from "../../../../../../../../data/validation/rules"
import Validation from "../../../../../../../../data/validation/validation"
import {ScrollIntoFirstError} from '../../../../../../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        creating: false,
        firstname: "",
        lastname: "",
        gender: "",
        school: "",
        dob: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 3, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 3, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.gender, (error) => errors.gender = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(DateValidationRule, instance.state.dob, (error) => errors.dob = error, { allowNull: false})
    validation.addValidationRule(StringValidationRule, instance.state.school, (error) => errors.school = error, { min: { value: 0, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addStudent = (instance) => {
    instance.setState({ ...instance.state, creating: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/students/create",
        headers : {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            gender: instance.state.gender,
            dob: new Date(instance.state.dob).getTime()/1000,
            school: instance.state.school
        }
    }).then(response => handleAddStudentResponse(instance, response))
    .catch(error => alert(error))
}

const handleAddStudentResponse = (instance, response) => {
    instance.setState({ ...instance.state, creating: false })
    switch(response.data.status){
        case 200:
            instance.props.onStudentReceived(response.data.data.student)
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addStudent: () => addStudent(instance)
    }
}

export default Service