import React from 'react'
import { connect } from 'react-redux'
import './dashboard.styles.scss'
import imageDashbord from '../../assets/icons/dashboard.svg'
import imageOrders from '../../assets/icons/payer.svg'
import imageSecurity from '../../assets/icons/security.svg'
import imageSettings from '../../assets/icons/config.svg'
import { withRouter } from 'react-router-dom'
import Service from './dashboard.service'

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {

    }

    render() {
        if (this.state.loading) {
            return (
                <div className="dashboard">
                    <div className="cards">
                        <p>loading...</p>
                    </div>
                </div>
            )
        }
        return (
            <div className="dashboard">
                <div className="buttons">
                    <div className="button membership" onClick={() => this.props.history.push("/applications/create/techcamp")}>
                        <div>
                            <img alt="" src={imageDashbord} />
                        </div>
                        <h4>Apply for TechCamp</h4>
                    </div>
                    <div className="button applications" onClick={() => this.props.history.push("/applications/all")}>
                        <div>
                            <img alt="" src={imageOrders} />
                        </div>
                        <h4>Make Payment</h4>
                    </div>
                    <div className="button program" onClick={() => this.props.history.push("/settings/profile")}>
                        <div>
                            <img alt="" src={imageSettings} />
                        </div>
                        <h4>Update Profile</h4>
                    </div>
                    <div className="button points" onClick={() => this.props.history.push("/settings/change-password")}>
                        <div>
                            <img alt="" src={imageSecurity} />
                        </div>
                        <h4>Change Password</h4>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard))