import { StringValidationRule } from "../../../../../../../../data/validation/rules"
import Validation from "../../../../../../../../data/validation/validation"
import {ScrollIntoFirstError} from '../../../../../../../../utils/UIHelper'

const getDefaultState = instance => {
    return {
        session: "",
        student: "",
        students: instance.props.students,
        sessions: instance.props.sessions,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.session, (error) => errors.session = error, { min: { value: 5, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.student, (error) => errors.student = error, { min: { value: 5, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => isValid(instance)
    }
}

export default Service