import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="firstname">
                    <div className="form-label">
                        <label>Firstname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row" id="lastname">
                    <div className="form-label">
                        <label>Lastname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                        <span className="error">{props.errors.lastname}</span>
                    </div>
                </div>
                <div className="row" id="gender">
                    <div className="form-label">
                        <label>Gender</label>
                    </div>
                    <div className="form-input">
                        <select name="gender" value={props.gender} onChange={props.onChange}>
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                        <span className="error">{props.errors.gender}</span>
                    </div>
                </div>
                <div className="row" id="date">
                    <div className="form-label">
                        <label>Date of Birth</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="dob" value={props.dob} onChange={props.onChange} />
                        <span className="error">{props.errors.dob}</span>
                    </div>
                </div>
                <div className="row" id="school">
                    <div className="form-label">
                        <label>Current School</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="school" value={props.school} onChange={props.onChange} />
                        <span className="error">{props.errors.school}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Save Participant</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form