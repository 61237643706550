import React from 'react'
import NumberFormat from 'react-number-format'
import './camp.styles.scss'

const Camp = props => {
    return (
        <div className="camp">
            <div>
                <h4>{props.student.firstname} {props.student.lastname}</h4>
                <p>{props.session.program.title}</p>
                <button onClick={() => props.onDeleteCamp(props.student.id)}>remove</button>
            </div>
            <span>
                <NumberFormat value={props.session.program.fee} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />
            </span>
        </div>
    )
}

export default Camp