import React from 'react'
import './student.styles.scss'
import imageAvatar from '../../../../../../../assets/images/avatar.png'
import { FormatDate } from '../../../../../../../utils/UIHelper'

const Student = props => {
    return (
        <div className="student">
            <img alt="" src={imageAvatar} />
            <div>
                <h4>{props.firstname} {props.lastname}</h4>
                <p><span>{props.gender}</span> {FormatDate(props.dob * 1000)}</p>
                <p>{props.school}</p>
            </div>
        </div>
    )
}

export default Student