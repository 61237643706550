import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule, PhoneValidationRule } from '../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        processing: false,
        tab: 0,
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => instance.state.errors.firstname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => instance.state.errors.lastname = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => instance.state.errors.phone = error, { allowNull: false, error: "Invalid" })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => instance.state.errors.email = error, { allowNull: false, error: "Invalid" })

    instance.setState({
        ...instance.state,
        errors: errors
    }, () => {
        for (let error in instance.state.errors) {
            if (instance.state.errors[error].length > 0) {
                ScrollIntoViewById(error)
                return
            }
        }
    })
    return validation.validate()
}

const create = (instance) => {
    instance.setState({
        ...instance.state,
        processing: true
    })
    axios({
            method: 'post',
            url: API_ENDPOINT + "/users/create",
            data: {
                firstname: instance.state.firstname.trim(),
                lastname: instance.state.lastname.trim(),
                phone: instance.state.phone.trim(),
                username: instance.state.email.trim().toLowerCase()
            }
        }).then(response => handleCreateResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                processing: false
            })
            alert(error)
        })
}

const handleCreateResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                tab: 1,
                processing: false,
                authorization: response.data.data.authorization
            })
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        create: () => create(instance)
    }
}

export default Worker