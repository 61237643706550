import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './complete.styles.scss'

const Complete = props => {
    return (
        <div className="complete">
            <h3>{props.title}</h3>
            <br/>
            <p>Please click <Link to="/auth/login">here</Link> to login</p>
        </div>
    )
}

export default withRouter(Complete)