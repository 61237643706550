import React from 'react'
import Service from './techcamp.service'
import Students from './components/students/students.component'
import Camps from './components/camps/camps.component'
import Success from './components/success/success.component'

class TechCamp extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    getCurrentTab = () => {
        switch (this.state.tab) {
            case 1:
                return <Camps students={this.state.students} onSuccess={this.onSuccess}/>
            case 2:
                return <Success orderNumber={this.state.orderNumbder}/>
            default:
                return <Students onStudentsReceived={this.onStudentsReceived}/>
        }
    }

    onStudentsReceived = students => {
        this.setState({
            ...this.state,
            students: students,
            tab: 1
        })
    }

    onSuccess = orderNumbder => {
        this.setState({
            ...this.state,
            tab: 2,
            orderNumbder: orderNumbder
        })
    }

    render() {
        return this.getCurrentTab()
    }
}

export default TechCamp