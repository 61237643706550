import React from 'react'
import { FLUTTERWAVE_PUBLIC_KEY } from '../../config'

const FlutterwavePopup = (props) => {
    let openPopup = () => {
        let handle = window.FlutterwaveCheckout({
            public_key: FLUTTERWAVE_PUBLIC_KEY,
            customer: {
                name: props.name,
                email: props.email,
            },
            amount: props.amount,
            currency: props.currency,
            tx_ref: props.txref,
            onclose: props.onClose,
            callback: (response) => props.callback(response, handle),
            customizations: {
                title: "SkysenxHub",
                description: "Payment for TechCamp",
                logo: "https://skysenx.com/assets/img/logo.png",
            }
        })
    }
    return (
        <div className="paystack">
            {openPopup()}
        </div>
    )
}

export default FlutterwavePopup