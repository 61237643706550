import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../../../../components/dialog/dialog.component'
import Loading from '../../../../../../components/loading/loading.component'
import { connect } from 'react-redux'
import './camps.styles.scss'
import Service from './camps.service'
import AddCamp from './components/add-camp/add-camp.component'
import Camp from './camp/camp.component'
import NumberFormat from 'react-number-format'

class Camps extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadSessions()
    }

    onNext = () => {
        if (this.state.camps.length === 0) {
            alert('Please add a Camp')
        }
        else {
            this.service.createOrder()
        }
    }

    toggleAddCamp = () => {
        this.setState({
            ...this.state,
            showAddCamp: !this.state.showAddCamp
        })
    }

    onCampReceived = camp => {
        if (this.alreadyInCamps(camp)) {
            return false
        }
        let camps = this.state.camps
        camps.push(camp)
        this.setState({
            ...this.state,
            camps: camps,
            showAddCamp: false
        })
        return true
    }

    alreadyInCamps = camp => {
        for (let i = 0; i < this.state.camps.length; i++) {
            if (this.state.camps[i].student.id === camp.student.id) {
                return true
            }
        }
        return false
    }

    onDeleteCamp = studentId => this.setState({
        ...this.state,
        camps: this.state.camps.filter(camp => camp.student.id !== studentId)
    })

    getTotal = () => {
        let total = 0;
        for (let i = 0; i < this.state.camps.length; i++) {
            total += Number(this.state.camps[i].session.program.fee)
        }
        return total
    }

    render() {
        return (
            <>
                {(this.state.loading || this.state.creating)&& <Dialog><Loading /></Dialog>}
                {this.state.showAddCamp && <Dialog><AddCamp onCancel={this.toggleAddCamp} onCampReceived={this.onCampReceived} students={this.props.students} sessions={this.state.sessions} /></Dialog>}
                <div className="camps">
                    <div className="header">
                        <h4>Training Camps</h4>
                    </div>
                    <div className="records">
                        {
                            this.state.camps.map((camp, key) => <Camp key={key} {...camp} onDeleteCamp={this.onDeleteCamp} />)
                        }
                    </div>
                    <div className="summary">
                        {
                            this.state.camps.length > 0 && <p>Total Fee: <NumberFormat value={this.getTotal()} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></p>
                        }
                    </div>
                    <div className="triggers">
                        <button onClick={this.toggleAddCamp}>Add Camp</button>
                        <button onClick={this.onNext}>Submit</button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Camps))