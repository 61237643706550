import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="student">
                    <div className="form-label">
                        <label>Select Participant</label>
                    </div>
                    <div className="form-input">
                        <select name="student" onChange={props.onChange} defaultValue={props.student}>
                            <option value=""></option>
                            {
                                props.students.map((student, key) => <option key={key} value={student.id}>{student.firstname} {student.lastname}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.student}</span>
                    </div>
                </div>
                <div className="row" id="session">
                    <div className="form-label">
                        <label>Select Camp</label>
                    </div>
                    <div className="form-input">
                        <select name="session" onChange={props.onChange} defaultValue={props.session}>
                            <option value=""></option>
                            {
                                props.sessions.map((session, key) => <option key={key} value={session.id}>{session.program.title} - N{session.program.fee}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.session}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Add</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form