import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        showAddCamp: false,
        camps: [],
        sessions: []
    }
}

const loadSessions = instance => {
    instance.setState({ ...instance.state, loading: true })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/training/sessions",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: (instance.state.currentPage - 1) * instance.state.pageSize,
            limit: 10,
            criteria: {
                status: 1
            }
        }
    }).then(response => handleLoadSessionsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadSessionsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                selectedIndex: -1,
                sessions: response.data.data.sessions,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const createOrder = instance => {
    instance.setState({ ...instance.state, creating: true })
    let trainingDetails = instance.state.camps.map(camp => (
        {
            sessionId: camp.session.id,
            description: camp.session.program.title,
            studentId: camp.student.id
        }
    ))

    axios({
        method: 'post',
        url: API_ENDPOINT + "/orders/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            trainingDetails: trainingDetails
        }
    }).then(response => handleCreateOrderResponse(instance, response))
        .catch(error => {
            instance.setState({ ...instance.state, creating: false })
            alert(error)
        })
}

const handleCreateOrderResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                creating: false
            })
            instance.props.onSuccess(response.data.data.order.number)
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
            instance.setState({
                ...instance.state,
                creating: false
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        loadSessions: () => loadSessions(instance),
        createOrder: () => createOrder(instance)
    }
}

export default Service