import React from 'react'
import './form.styles.scss'
import States from '../../../../assets/data/states'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Fristname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Lastname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                        <span className="error">{props.errors.lastname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="phone" value={props.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                <div className="form-input" id="addressLine1">
                    <label>Address</label>
                    <input type="text" name="addressLine1" placeholder="Street name" value={props.addressLine1} onChange={props.onChange} />
                    <span className="error">{props.errors.addressLine1}</span>
                </div>
                <div className="form-input" id="addressLine2">
                    <label></label>
                    <input type="text" name="addressLine2" placeholder="Area" value={props.addressLine2} onChange={props.onChange} />
                    <span className="error">{props.errors.addressLine2}</span>
                </div>
                <div className="form-input" id="city">
                    <label>City</label>
                    <input type="text" name="city" value={props.city} onChange={props.onChange} />
                    <span className="error">{props.errors.city}</span>
                </div>
                <div className="form-input" id="state">
                    <label>State</label>
                    <select name="state" value={props.state} onChange={props.onChange}>
                        <option value=""></option>
                        {
                            States.map((state, key) => <option key={key} value={state}>{state}</option>)
                        }
                    </select>
                    <span className="error">{props.errors.state}</span>
                </div>
                <div className="form-input">
                    <label>Country</label>
                    <input type="text" name="country" disabled value={"Nigeria"} />
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>Save</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form