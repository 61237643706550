import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './success.styles.scss'

const Success = props => {
    return (
        <div className="card success">
            <div className="header">
                <h4>Application #{props.orderNumber} Created</h4>
            </div>
            <br />
            <p>Please click <Link to="/applications/all">here</Link> to make payment</p>
        </div>
    )
}

export default withRouter(Success)