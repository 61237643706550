import React from 'react'
import './add-camp.styles.scss'
import Service from './add-camp.service'
import FormHelper from '../../../../../../../../utils/FormHelper'
import Form from './form.component'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class AddCamp extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.addCamp()
    }

    addCamp = () => {
        let camp = {
            session: this.state.sessions.filter(session => session.id === this.state.session)[0],
            student: this.state.students.filter(student => student.id === this.state.student)[0]
        }
        let ok = this.props.onCampReceived(camp)
        if(!ok){
            this.setState({
                ...this.state,
                flag: {
                    type: "error",
                    text: "Participant already added"
                }
            })
        }
    }

    render() {
        return (
            <div className="card add-camp">
                <div className="header">
                    <h4>Add Participant to a Camp</h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(AddCamp))