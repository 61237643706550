import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule } from '../../../data/validation/rules'

const getDefaultState = () => {
    return {
        saving: false,
        loading: false,
        firstname: "",
        lastname: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const load = (instance) => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/user",
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(error)
        })
}

const handleLoadResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                firstname: response.data.data.user.firstname,
                lastname: response.data.data.user.lastname,
                phone: response.data.data.user.phone,
                addressLine1: response.data.data.user.addressLine1,
                addressLine2: response.data.data.user.addressLine2,
                city: response.data.data.user.city,
                state: response.data.data.user.state,
                country: response.data.data.user.country
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => errors.phone = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.addressLine1, (error) => instance.state.errors.addressLine1 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.addressLine2, (error) => instance.state.errors.addressLine2 = error, { min: { value: 3, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.city, (error) => instance.state.errors.city = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.state, (error) => instance.state.errors.state = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const update = instance => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/users/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            addressLine1: instance.state.addressLine1,
            addressLine2: instance.state.addressLine2,
            city: instance.state.city,
            state: instance.state.state,
            country: instance.state.country
        }
    }).then(response => handleSaveResponse(instance, response)).catch(error => {
        instance.setState({
            ...instance.state,
            saving: false
        })
        alert(error)
    })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        isValid: () => isValid(instance),
        load: () => load(instance),
        update: () => update(instance),
        getDefaultState: () => getDefaultState()
    }
}

export default Service