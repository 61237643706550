import React from 'react'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <br />
            <div className="form-input">
                <label>Enter Code</label>
                <input autoFocus={true} type="text" name="code" value={props.code} onChange={props.onChange} maxLength="6" />
            </div>
            <div className="actions">
                <button>Continue</button>
            </div>
        </form>
    )
}

export default Form