
const getDefaultState = () => {
    return {
        loading: false,
        tab: 0
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState
    }
}

export default Service