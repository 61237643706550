import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        showAddStudent: false,
        students: [],
        filter: {
            column: "",
            value: ""
        },
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadStudents = instance => {
    instance.setState({...instance.state, loading: true })
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }
    axios({
            method: 'post',
            url: API_ENDPOINT + "/training/students",
            headers: {
                "Authorization": instance.props.auth.authorization
            },
            data: {
                offset: (instance.state.currentPage - 1) * instance.state.pageSize,
                limit: instance.state.pageSize,
                criteria: criteria
            }
        }).then(response => handleLoadStudentsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadStudentsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                selectedIndex: -1,
                students: response.data.data.students,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        loadStudents: () => loadStudents(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service