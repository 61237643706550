import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'
import iconSettings from '../../../assets/icons/config.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [{
            text: "Dashboard",
            icon: iconDashboard,
            subItems: []
        },
        {
            text: "Applications",
            icon: iconPayer,
            subItems: [{
                    url: "/applications/create/techcamp",
                    text: "New Application"
                },
                {
                    url: "/applications/all",
                    text: "Applications"
                }
            ]
        },
        {
            text: "Payments",
            icon: iconDashboard,
            subItems: [{
                    url: "/payments/all",
                    text: "Payments"
                }
            ]
        },
        {
            text: "Settings",
            icon: iconSettings,
            subItems: [{
                    url: "/settings/profile",
                    text: "Profile"
                },
                {
                    url: "/settings/change-password",
                    text: "Change Password"
                }
            ]
        }
    ]
}

export default DefaultStore