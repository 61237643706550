import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../../../../components/dialog/dialog.component'
import Loading from '../../../../../../components/loading/loading.component'
import { connect } from 'react-redux'
import './students.styles.scss'
import Service from './students.service'
import AddStudent from './components/add-student/add-student.component'
import Student from './student/student.component'

class Students extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadStudents()
    }

    onNext = () => {
        this.props.onStudentsReceived(this.state.students)
    }

    toggleAddStudent = () => {
        this.setState({
            ...this.state,
            showAddStudent: !this.state.showAddStudent
        })
    }

    onStudentReceived = student => {
        let students = this.state.students
        students.push(student)
        this.setState({
            ...this.state,
            students: students,
            showAddStudent: false
        })
    }

    onDelete = id => this.setState({
        ...this.state,
        showDeleteDialog: false,
        documents: this.state.documents.filter(document => document.id !== id)
    })

    showDeleteDialog = document => this.setState({
        ...this.state,
        showDeleteDialog: true,
        documentToDelete: document
    })

    hideDeleteDialog = () => this.setState({
        ...this.state,
        showDeleteDialog: false,
        documentToDelete: null
    })

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showAddStudent && <Dialog><AddStudent onCancel={this.toggleAddStudent} onStudentReceived={this.onStudentReceived} /></Dialog>}
                <div className="students">
                    <div className="header">
                        <h4>Participants</h4>
                    </div>
                    <div className="records">
                        {
                            this.state.students.map((student, key) => <Student key={key} {...student} />)
                        }
                    </div>
                </div>
                <div className="triggers">
                    <button onClick={this.toggleAddStudent}>Add Participant</button>
                    <button onClick={this.onNext}>Next</button>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Students))